import api from './api'
export async function getPoi(slug) {
    const data = await api.get(`${process.env.API_URL}/poi?slug=${slug}`)
    .then(res => {
        return res.data
    })
    .catch(err => {
        console.log(err);
        return []
    })
    return data
}